import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import {ProgressBarStyle} from "./components/LoadingScreen";
import ScrollToTop from "./components/ScrollToTop";
import {SnackbarProvider} from 'notistack'
import Router from "./routes";

function App() {
  return (
    <ThemeConfig>
      <GlobalStyles />
      <ProgressBarStyle />
      <ScrollToTop />
      <SnackbarProvider maxSnack={4} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Router/>
      </SnackbarProvider>
    </ThemeConfig>
  );
}

export default App;
