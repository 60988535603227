import {useState, useEffect} from 'react';
import groq from 'groq';
import { client } from '../../utils/client';

const query = groq`
  *[_type == 'category' ] {
    _id,
    description,
    title
  }
`

function formatTitle(str) {
  if (str.includes('-')) {
    let result = '';
    const separated = str.split('-');
    separated.forEach(x => {
      result = `${result} ${(x.charAt(0).toUpperCase() + x.slice(1))}`
    })
    return result.trim();
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1).trim()
  }
}
export default function useGetMenuItems() {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getMenuItems() {
    try {
      const response = await client.fetch(query);
      setMenuItems(response.map(category =>  {
        return {
          title: formatTitle(category.title),
          path: `/categorias/${category.title}`
        }
      }))
    } catch (e) {
      setError(e)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  const [error, setError] = useState(null);

  useEffect(() => {
    getMenuItems();
  }, [])

  return {menuItems, loading, error};
}
